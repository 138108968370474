<template>
  <div class="filingALawsuitListContainer">
    <div class="fAListTitle">
      {{ material_type.desc }}
    </div>
    <div class="fAListBody">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了~"
        @load="onLoad"
      >
        <div
          class="fAListBodyItemContainer"
          v-for="(item, index) in goodList"
          :key="index"
          @click.stop="goLitigationDetails(item)"
        >
          <div class="fAListBodyItemTitle">
            <div class="fAListBodyItemTitleLeft">
              诉讼案件号：{{ item.lawsuit_no }}
            </div>
            <img
              src="../../../static/images/righticon@2x.png"
              alt=""
              srcset=""
              class="righticon"
            />
          </div>
          <div class="fAListBodyItemBody">
            <div class="defendantContainer">
              <div class="defendantNameTitle">被告姓名：</div>
              <div class="defendantName">{{ item.real_name }}</div>
            </div>
            <div class="identityCardContainer">
              <div class="defendantNameTitle">身份证号：</div>
              <div class="defendantName">{{ item.identity_card_number }}</div>
            </div>
            <div class="identityCardContainer">
              <div class="defendantNameTitle">索赔金额：</div>
              <div class="defendantName">{{ item.lawsuit_amount }}元</div>
            </div>
            <img
              src="../../../static/images/proceedingLogo@2x.png"
              alt=""
              class="defendantLogo"
            />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getMaterialList } from "@/api/security";
export default {
  name: "FilingALawsuitList",
  props: ["is_navbar"],
  data() {
    return {
      goodList: [],
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      type: 2,
      material_type: {},
    };
  },
  methods: {
    onLoad() {
      let config = {
        type: this.type,
        page: this.page,
        page_size: this.page_size,
      };
      this.requestGetMaterialList(config);
    },
    // 立案诉讼详情
    goLitigationDetails(item) {
      this.$router.push({
        name: "LitigationDetails",
        query: {
          id: item.id,
          is_navbar: this.is_navbar,
        },
      });
    },
    // 商品列表请求
    async requestGetMaterialList(config) {
      this.loading = true;
      try {
        const result = await getMaterialList(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.material_type = result.data.material_type;

          let goodList = [...this.goodList, ...result.data.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.filingALawsuitListContainer {
  .fAListTitle {
    width: 336px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 20px;
    padding-bottom: 20px;
  }
  .fAListBody {
    .fAListBodyItemContainer {
      width: 335px;
      height: 145px;
      background: #f9f9f9;
      border-radius: 8px;
      margin-bottom: 12px;
      .fAListBodyItemTitle {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px 0px 12px;
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
        .fAListBodyItemTitleLeft {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 20px;
        }
        .righticon {
          width: 16px;
          height: 20px;
        }
      }
      .fAListBodyItemBody {
        width: 100%;
        height: 100px;
        padding: 12px;
        box-sizing: border-box;
        position: relative;
        .defendantContainer {
          display: flex;
          align-items: center;
          .defendantNameTitle {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
          .defendantName {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
          }
        }
        .identityCardContainer {
          display: flex;
          align-items: center;
          margin-top: 8px;
          .defendantNameTitle {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
          .defendantName {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
          }
        }
        .defendantLogo {
          width: 76px;
          height: 76px;
          position: absolute;
          right: 12px;
          bottom: 12px;
        }
      }
    }
  }
}
</style>
