<template>
  <div class="page">
    <van-nav-bar
      :title="title"
      :safe-area-inset-top="true"
      :border="false"
      :fixed="true"
      :placeholder="true"
      v-show="from === 'H5' && is_navbar == 'true'"
      left-arrow
      @click-left="onClickLeft"
    />
    <NavigationBar
      :title="title"
      v-show="from !== 'H5' && is_navbar == 'true'"
    ></NavigationBar>
    <div class="blacklistExposureContainer" :style="{ top: this.offsetTop }">
      <div class="bETitle">
        <div class="bETitleContainer">
          <div class="imgContainer">
            <img
              src="../../static/images/blacklistexposure@2x.png"
              alt=""
              srcset=""
              class="blacklistExposureImg"
            />
          </div>
          <div class="fieldContainer">
            <div class="fieldCenter">
              <div class="fieldItem" @click="goCustomerServiceVer">
                <div class="fieldItemTop">
                  <div class="fieldItemTopLeft">
                    <img
                      src="../../static/images/phoneIcon@2x.png"
                      alt=""
                      srcset=""
                      class="fieldItemTopLeftIcon"
                    />
                    <div class="fieldItemTopLeftText">客服验证</div>
                  </div>
                  <div class="fieldItemTopRight">
                    <img
                      src="../../static/images/righticon@2x.png"
                      alt=""
                      class="righticon"
                    />
                  </div>
                </div>
                <div class="fieldItemBottom">
                  输入客服的联系手机号，辨别真假客服
                </div>
              </div>
              <van-divider />
              <div class="fieldItem" @click="goBlacklistQuery">
                <div class="fieldItemTop">
                  <div class="fieldItemTopLeft">
                    <img
                      src="../../static/images/peopleIcon@2x.png"
                      alt=""
                      srcset=""
                      class="fieldItemTopLeftIcon"
                    />
                    <div class="fieldItemTopLeftText">黑名单查询</div>
                  </div>
                  <div class="fieldItemTopRight">
                    <img
                      src="../../static/images/righticon@2x.png"
                      alt=""
                      class="righticon"
                    />
                  </div>
                </div>
                <div class="fieldItemBottom">
                  输入游戏账号/手机号/身份证号，一键扒出账号黑历史
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bEBodycontainer">
        <div class="bEBodyTabs">
          <van-tabs
            line-width="96px"
            line-height="2px"
            color="#00A8FF"
            title-active-color="#00A8FF"
            title-inactive-color="#AAAAAA"
            :sticky="true"
            :offset-top="offsetTop"
          >
            <van-tab title="账号找回公示">
              <div class="bEBodyListContainer">
                <AccountRetrievalList
                  :is_navbar="is_navbar"
                ></AccountRetrievalList>
              </div>
            </van-tab>
            <van-tab title="立案起诉公示">
              <div class="bEBodyListContainer">
                <FilingALawsuitList :is_navbar="is_navbar"></FilingALawsuitList>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountRetrievalList from "@/pages/BlacklistExposure/components/AccountRetrievalList.vue";
import FilingALawsuitList from "@/pages/BlacklistExposure/components/FilingALawsuitList.vue";
import { NavigationBar } from "@/components";
import { gitIosAppleModels } from "@/utils/gitIosAppleModels";
export default {
  name: "BlacklistExposure",
  data() {
    return {
      title: "黑名单曝光台",
      offsetTop: "",
      isiOS: "",
      from: "",
      is_navbar: "true",
    };
  },
  components: {
    NavigationBar,
    AccountRetrievalList,
    FilingALawsuitList,
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.path == "/exposuredetails" ||
      to.path == "/litigationdetails" ||
      to.path == "/customerservicever" ||
      to.path == "/blacklistquery"
    ) {
      if (!from.meta.keepAlive) {
        from.meta.keepAlive = true;
      }
      next();
    } else {
      from.meta.keepAlive = false;
      next();
    }
  },
  mounted() {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      this.offsetTop = "1.226667rem";
    } else if (isiOS) {
      let iosSafeArea = this.$route.query.iosSafeArea;
      let ios_6_7_8 = gitIosAppleModels();
      if (ios_6_7_8) {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : "1.226667rem";
      } else {
        this.offsetTop = iosSafeArea
          ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
          : 90 / (document.documentElement.clientWidth / 10) + "rem";
      }
    }
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    if (this.$route.query.is_navbar) {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
    if (this.is_navbar == "false") {
      this.offsetTop = "0rem";
    }
  },
  methods: {
    goCustomerServiceVer() {
      this.$router.push({
        name: "CustomerServiceVer",
        query: {
          is_navbar: this.is_navbar,
        },
      });
    },
    goBlacklistQuery() {
      this.$router.push({
        name: "BlacklistQuery",
        query: {
          is_navbar: this.is_navbar,
        },
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  .blacklistExposureContainer {
    width: 100%;
    height: calc(100vh - 46px);
    position: absolute;
    top: 46px;
    .bETitle {
      height: 270px;
      .bETitleContainer {
        position: relative;
        .imgContainer {
          width: 100%;
          height: 125px;
          .blacklistExposureImg {
            width: 100%;
            height: 100%;
          }
        }
        .fieldContainer {
          width: 345px;
          height: 159px;
          background: #ffffff;
          box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.5);
          border-radius: 8px;
          position: absolute;
          top: 95px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          .fieldCenter {
            width: 305px;
            height: 119px;
            .fieldItem {
              .fieldItemTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .fieldItemTopLeft {
                  display: flex;
                  align-items: center;
                  .fieldItemTopLeftIcon {
                    width: 18px;
                    height: 18px;
                  }
                  .fieldItemTopLeftText {
                    height: 22px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #444444;
                    line-height: 22px;
                    margin-left: 4px;
                  }
                }
                .fieldItemTopRight {
                  width: 16px;
                  height: 20px;
                  .righticon {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .fieldItemBottom {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #888888;
                line-height: 17px;
                padding-top: 8px;
              }
            }
          }
        }
      }
    }
    .bEBodycontainer {
      width: 375px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(213, 213, 213, 0.5);
      border-radius: 16px;
      .bEBodyTabs {
        .bEBodyListContainer {
          width: 336px;
          padding-top: 20px;
          margin: 0 auto;
        }
      }
    }
  }
}

::v-deep .van-divider {
  margin: 12px 0px;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  border-bottom: 1px solid #f2f2f2;
  border-radius: 16px 16px 0px 0px;
}
::v-deep .van-tab {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
.safe-area-inset-top {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
</style>
