<template>
  <div class="accountRetrievalListContainer">
    <div class="aRListTitle">
      {{ material_type.desc }}
    </div>
    <div class="aRListBodyList">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了~"
        @load="onLoad"
      >
        <van-row class="th-row" style="display: flex">
          <van-col span="6" class="van-col-title">姓名</van-col>
          <van-col span="6" class="van-col-title">账号</van-col>
          <van-col span="6" class="van-col-title">游戏</van-col>
          <van-col span="6" class="van-col-title">操作</van-col>
        </van-row>
        <van-row
          class="th-row-item"
          v-for="(item, index) in goodList"
          :key="index"
          :style="{ background: index % 2 == 0 ? '#fff' : ' #f5f5f5' }"
        >
          <van-col span="6" class="van-col-listItem">{{
            item.real_name
          }}</van-col>
          <van-col span="6" class="van-col-listItem">{{
            item.account
          }}</van-col>
          <van-col span="6" class="van-col-listItem">{{
            item.game_name
          }}</van-col>
          <van-col
            span="6"
            class="van-col-listLink"
            @click="goExposureDetails(item)"
            >详情</van-col
          >
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getMaterialList } from "@/api/security";
export default {
  name: "AccountRetrievalList",
  props: ["is_navbar"],
  data() {
    return {
      goodList: [],
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      page: 1,
      page_size: 20,
      type: 1,
      material_type: {},
    };
  },
  methods: {
    onLoad() {
      let config = {
        type: this.type,
        page: this.page,
        page_size: this.page_size,
      };
      this.requestGetMaterialList(config);
    },
    goExposureDetails(item) {
      this.$router.push({
        name: "ExposureDetails",
        query: {
          id: item.id,
          is_navbar: this.is_navbar,
        },
      });
    },
    // 商品列表请求
    async requestGetMaterialList(config) {
      this.loading = true;
      try {
        const result = await getMaterialList(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.material_type = result.data.material_type;
          let goodList = [...this.goodList, ...result.data.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.accountRetrievalListContainer {
  .aRListTitle {
    width: 336px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 20px;
    padding-bottom: 20px;
  }
  .aRListBodyList {
    .th-row {
      height: 30px;
      background: #f5f5f5;
      line-height: 30px;
      .van-col-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        text-align: center;
      }
    }
    .th-row-item {
      height: 30px;
      line-height: 30px;
      .van-col-listItem {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        text-align: center;
      }
      .van-col-listLink {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #00a8ff;
        text-align: center;
      }
    }
  }
}
</style>
